import { get, post } from "./api";

export const sendRequestPassword = (email: string) => {
  return post("/admin/sendPasswordReset", { email });
};

export const getUserDetails = (userId: string) => {
    return get("/admin/user/", { userId });
}

export const getAllUsers = () => {
  return get("/admin/allUsers");
};

export const updateUser = (user: any) => {
  return post("/admin/updateUser", { user });
}

export const loginAsUser = (email: string) => {
  return post("/admin/loginAsUser", { email });
}

import React, { useEffect, useState } from "react";
import { LayoutWrapper } from "../../../components/layoutWrapper";
import useStyles from "../../../components/util/styles";
import LockIcon from "@material-ui/icons/Lock";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import {
  sendRequestPassword,
  getUserDetails,
  updateUser,
} from "../../../lib/adminRequests";
import { useHistory, useParams } from "react-router-dom";
import { UserExtended } from "../../../context/authContext/reducer";
import { Alert } from "@material-ui/lab";
import { roleOptions, currencies } from "../../../constants";
import TextDivider from "../../../components/textDivider";
import { useAlert } from "../../../context/alertContext";
import { useAuth } from "../../../context/authContext";
import { loginAsUserFromAdmin, logout } from "../../../context/authContext/actions";
import { useCellarsContext } from "../../../context/cellarsContext";

export const AdminUser = () => {
  const classes = useStyles();
  const [user, setUser] = useState<UserExtended>();
  const [initialError, setInitialError] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  const { userId } = useParams<{ userId: string }>();
  const alert = useAlert();
  const history = useHistory();
  const { dispatch } = useAuth();
  const { dispatch: cellarsDispatch } = useCellarsContext();


  const handleResetPassword = async () => {
    try {
      const email = user?.email;
      if (!email) {
        throw new Error("No email found for this user");
      }
      await sendRequestPassword(email);
      alert.openAlert("Password reset email sent", "success", 6000);
    } catch (err: any) {
      alert.openAlert(err.message, "error", 6000);
    }
  };

  const handleUserUpdate = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const updatedUser = {
        id: user?.id,
        email: user?.email,
        name: user?.name,
        last_name: user?.last_name,
        role: user?.role,
        street_address: user?.street_address,
        street_address_line2: user?.street_address_line2,
        city: user?.city,
        state: user?.state,
        zip: user?.zip,
        country: user?.country,
        new_password: user?.new_password,
        confirm_password: user?.confirm_password,
        currency: user?.currency,
      };

      if (updatedUser?.new_password && updatedUser?.confirm_password){
        if (updatedUser.new_password !== updatedUser.confirm_password) {
          alert.openAlert("Passwords do not match", "error", 6000);
          return;
        } else if ((updatedUser?.new_password?.length ?? 0) < 6) {
          alert.openAlert("Password must be at least 6 characters long", "error", 6000);
          return;
        }
      }

      const { data } = await updateUser(updatedUser);
      alert.openAlert(data?.msg || 'User was updated', "success", 6000);
    } catch (err: any) {
      alert.openAlert(err?.message || 'Something went wrong', "error", 6000);
    }
  };

  const handleLoginAsUser = async () => {
    try {
      const email = user?.email;
      if (!email) {
        throw new Error("No email found for this user");
      }

      await loginAsUserFromAdmin(dispatch, cellarsDispatch, email);
      history.push("/cellars")
    } catch (err: any) {
      alert.openAlert(err.message, "error", 6000);
    }
  }

  useEffect(() => {
    try {
      const getUser = async () => {
        const userDetailsResponse = await getUserDetails(userId);
        if (userDetailsResponse?.user) {
          const userData = userDetailsResponse.user;
          const subscription = userData.subscription;
          const currency = currencies.find(
            (c) =>
              c.value === subscription?.planCurrency
          );
          setUser({
            ...userDetailsResponse.user,
            subscription: {
              ...userData.subscription,
              currencySymbol: currency?.symbol || "$",
            },
          });
        } else {
          setInitialError(`${userDetailsResponse}`);
        }
      };
      getUser();
    } catch (err: any) {
      setInitialError(err.message);
    }
  }, [userId]);

  useEffect(() => {
    if (user?.confirm_password && user?.confirm_password !== user?.new_password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  }, [user?.confirm_password, user?.new_password])

  return (
    <LayoutWrapper pathName="admin/user">
      <Container maxWidth="lg" className={classes.container}>
        {user ? (
          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <List>
                  <ListItem>
                    <Typography component="h1" variant="h5">
                      Profile
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      autoFocus
                      variant="outlined"
                      disabled
                      id="email"
                      label="Email Address"
                      name="email"
                      value={user.email || ""}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="fname"
                      name="firstName"
                      id="firstName"
                      label="First Name"
                      value={user?.name || ""}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          name: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="lname"
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      value={user?.last_name || ""}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          last_name: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem style={{ marginBottom: 4 }}>
                    <FormControl
                      className={classes.settingsField}
                      required
                      variant={"filled"}
                      size="medium"
                    >
                      <InputLabel shrink htmlFor="age-native-label-placeholder">
                        Role
                      </InputLabel>
                      <Select
                        native
                        value={user?.role || "Enthusiast"}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            role: e.target.value as string,
                          });
                        }}
                      >
                        {roleOptions.map((option: string) => (
                          <option value={option} key={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="streetAddress"
                      id="streetAddress"
                      label="Street Address"
                      name="streetAddress"
                      value={user?.street_address || ""}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          street_address: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      autoComplete="addressContd"
                      id="addressContd"
                      label="Address (contd.)"
                      name="addressContd"
                      value={user?.street_address_line2 || ""}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          street_address_line2: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="city"
                      id="city"
                      label="City"
                      name="city"
                      value={user?.city || ""}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          city: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="state"
                      id="state"
                      label="State/Province/Region"
                      name="state"
                      value={user?.state || ""}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          state: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="zip"
                      id="zip"
                      label="Zip/Postal Code"
                      name="zip"
                      value={user?.zip || ""}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          zip: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="country"
                      id="country"
                      label="Country"
                      name="country"
                      value={user?.country || ""}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          country: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <List>
                  <ListItem>
                    <Typography component="h1" variant="h5">
                      Subscription
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography style={{ color: "#b7b7c7" }}>
                      {`${user?.name} is currently subscribed to plan: Cellar Watch ${user.subscription.planName || 'No plan'} (${user.subscription.currencySymbol}${user.subscription.planPrice || 0}/${user.subscription.planInterval || 'month'})`}
                    </Typography>
                  </ListItem>
                  {user.subscription.renewsAt &&
                    !user.subscription.isOnFreeTrialUntil && (
                      <ListItem>
                        <Typography style={{ color: "#b7b7c7" }}>
                          {user.subscription.canceledAt
                            ? `The subscription will be canceled automatically on ${format(
                                user.subscription.canceledAt,
                                "MMMM do, yyyy"
                              )}`
                            : `The subscription will automatically renew on ${format(
                                user.subscription.renewsAt,
                                "MMMM do, yyyy"
                              )}`}
                        </Typography>
                      </ListItem>
                    )}
                  {!!user.subscription.isOnFreeTrialUntil && (
                    <ListItem>
                      <Typography style={{ color: "#b7b7c7" }}>
                        `You are on a free trial until{" "}
                        {format(
                          user.subscription.isOnFreeTrialUntil,
                          "MMMM do, yyyy"
                        )}
                        `
                      </Typography>
                    </ListItem>
                  )}
                  <ListItem>
                    <Typography component="h1" variant="h5">
                      Password
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="new-password"
                      name="new-password"
                      label="New Password"
                      type="password"
                      id="new-password"
                      value={user?.new_password || ""}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          new_password: e.target.value,
                        });
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="new-password-confirmation"
                      name="new-password-confirmation"
                      label="Confirm New Password"
                      type="password"
                      id="new-password-confirmation"
                      value={user?.confirm_password || ""}
                      error={confirmPasswordError.length > 0}
                      helperText={confirmPasswordError}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          confirm_password: e.target.value,
                        });
                      }}
                    />
                  </ListItem>
                  <TextDivider children="OR"></TextDivider>
                  <ListItem>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.marginBottom24}
                      onClick={() => {
                        handleResetPassword();
                      }}
                    >
                      <ListItemIcon>
                        <LockIcon />
                      </ListItemIcon>
                      <ListItemText primary="Send Password Reset Email to User" />
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Typography component="h1" variant="h5">
                      Actions
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.marginBottom24}
                      onClick={() => {
                        handleLoginAsUser();
                      }}
                    >
                      <ListItemText primary="Login as User" />
                    </Button>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => handleUserUpdate(e)}
              >
                Save
              </Button>
            </Box>
          </form>
        ) : initialError ? (
          <Alert severity="error">{initialError}</Alert>
        ) : (
          <></>
        )}
      </Container>
    </LayoutWrapper>
  );
};
